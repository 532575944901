<!-- 模块说明 -->
<template>
  <div class="login-view">
    <div class="login-box">
      <div class="title">
        <div class="t1">海恒科技管理后台</div>
        <div class="t2">欢迎登录</div>
      </div>
      <div class="pwdRegister_content login-content">
        <el-form :model="ruleForm" ref="ruleForm" status-icon :rules="rules">
          <el-form-item status-icon prop="phone">
            <el-input
              prefix-icon="el-icon-user"
              placeholder="请输入账号"
              maxlength="20"
              v-model="ruleForm.phone"
            ></el-input>
          </el-form-item>
          <el-form-item status-icon prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              v-model="ruleForm.password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item size="large">
            <div
              class="btn"
              @click="throttleLogin"
              :disabled="loading"
              id="confirmBtn"
              v-loading.fullscreen.lock="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              element-loading-text="登录中..."
            >
              登录
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      ruleForm: {},
      loading: false,
      rules: {
        phone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入账号",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "请输入密码",
          },
        ],
      },
    };
  },
  watch: {},
  created() {},
  methods: {
    throttleLogin() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let cs = this.ruleForm;
          let params = {
            user: cs.phone,
            password: cs.password,
          };
          this.http
            .post("/login/loginPassword", params)
            .then((res) => {
              if (res.data.success) {
                this.setCacheData("userInfo", JSON.stringify(res.data.data));
                this.$router.push('/manager')
              } else {
                this.$message.error(res.data.errorMsg);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.pwdRegister_content {
  .el-input {
    font-size: 16px;
  }
  /deep/.el-input__inner {
    height: 60px !important;
    font-size: 16px;
  }
  margin-top: 50px;
  #confirmBtn {
    background: #006eff;
  }

  .btn {
    cursor: pointer;
    height: 60px;
    width: 100%;
    border-radius: 4px;
    background: #d8d8d8;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
  }

  .otherRegister {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    font-size: 16px;
    .toVerify {
      color: #006eff;
      float: left;
      cursor: pointer;
    }

    .toForget {
      cursor: pointer;
      color: #666666;
      float: right;
    }
  }
}
.login-view {
  .login-box {
    .title {
      .t2 {
        font-size: 28px;
      }
      .t1 {
        font-size: 38px;
        margin-bottom: 30px;
      }
      font-weight: bold;
      color: white;
      text-align: center;
    }
    width: 423px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 80px 60px;
  }
  background: url("../../images/home/about/p18.png") no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
</style>
